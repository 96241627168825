const handle1 = 'KesleyDavid';
const handle2 = 'kesleydavid';
const handle3 = 'KesleyDEV';
const handle4 = 'hi@kesley.dev';
const handle5 = '+55 62 9 9698 0747';
// const handleTwitter = 'kesley_david';
// const handleYoutube = 'UCtwG53vrewBT4L_nVnCor5w';
// const handleDiscord = 'vnwMJxp';

const links = [
  {
    label: "Wpp",
    url: `https://wa.me/message/T4F35FFRP7ZSI1`,
    value: `${handle5}`,
   },
  {
   label: "Mail",
   url: `mailto:hi@kesley.dev`,
   value: `${handle4}`,
  },
  {
    label: "Telegram",
    url: `https://t.me/${handle3}`,
    value: `${handle3}`,
  },
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/in/${handle1}`,
    value: `${handle2}`,
  },
  {
    label: "Github",
    url: `https://github.com/${handle1}`,
    value: `${handle1}`,
  },
  // {
  //   label: "Youtube",
  //   url: `https://www.youtube.com/user/${handleYoutube}`,
  // },
  // {
  //   label: "Twitter",
  //   url: `https://twitter.com/${handleTwitter}`,
  // },
  // {
  //   label: "Behance",
  //   url: `http://be.net/${handle1}`,
  // },
  // {
  //   label: "Gitlab",
  //   url: `https://gitlab.com/${handle1}`,
  // },
  // {
]

export default links