import styled from "styled-components"
import media from "styled-media-query"
import { Link as oLink } from "gatsby"

export const fl_Wrapper = styled.div`
  /* width: 197px; */
  /* height: 75px; */
  width: 100%;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`