import styled from "styled-components"
import media from "styled-media-query"

export const sm_Wrapper = styled.div`
  width: 100%;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`

export const sm_Link = styled.a`
  display: flex;
  color: #506a85;
  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  &:hover {
    color: #1fa1f2;
    svg {
      transition: fill 0.5s;
      fill: #1fa1f2 !important;
    }
  }
`
export const sm_IconWrapper = styled.div`
  fill: #506a85;
  width: 19px;
`

export const sm_TextWrapper = styled.div`
  /* fill: #506a85; */
  /* height: 19px; */
`

export const sm_TextMail = styled.span`
  font-size: 12px;
  margin-left: 5px;
`
