import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.div`
  width: 100%;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`

export const List = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-around;
  list-style: none;
`

export const sl_Item = styled.li`
padding: 0 0 0 10px;
`

export const sl_Link = styled.a`
  color: #506a85;
  text-decoration: none;
  transition: color 0.5s;
  &:hover {
    color: #1fa1f2;
    svg {
      fill: #1fa1f2 !important;
      color: #1fa1f2 !important;
    }
  }
`
export const sl_IconWrapper = styled.div`
  /* Preenchimento Inicial */
  fill: #506a85;
  width: 19px;
  /* height: 13px; */
`
