import { Github } from "@styled-icons/boxicons-logos"
// import { Github, Twitter, Youtube } from "@styled-icons/boxicons-logos"
import { Linkedin } from "styled-icons/boxicons-logos"
import { Telegram } from "styled-icons/boxicons-logos"
// import { Discord } from "styled-icons/boxicons-logos/Discord"
import { Mail } from "styled-icons/material"
// import { Instagram } from "styled-icons/boxicons-logos/Instagram"
// import { Behance } from "styled-icons/boxicons-logos/Behance"
// import { Gitlab } from "styled-icons/boxicons-logos/Gitlab"

const Icons = {
  // Youtube,
  // Twitter,
  Github,
  Linkedin,
  Telegram,
  // Discord,
  Mail,
// Instagram,
// Behance,
// Gitlab,
}

export default Icons