import styled from "styled-components"
import media from "styled-media-query"

export const hn_Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 100vw; */
  height: 112px;
  background: #ffffff;
  border-bottom: 2px solid #e6e9ed;

  /* ${media.between("768px", "999px")`
    height: 85px;
  `}
  ${media.between("1000px", "1023px")`
    height: 100px;
  `} */
  ${media.lessThan("1023px")`
    height: 100px;
  `}
  
`

export const hn_Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  padding: 0 15px;

  ${media.lessThan("1334px")`
    max-width: 90vw;
  `}
  
  /* kesDev */
  /* background: #123456; */
`

export const hn_NavLogo = styled.div`
  /* height: 19px; */
  /* width: 100%; */
  width: 17%;
`

export const hn_NavItens = styled.div`
  width: 83%;
  ${media.lessThan("medium")`
    /* display: none; */
  `}
`
