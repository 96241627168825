import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const {
    logoImage,
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "kesleydev-name.png" }) {
          childImageSharp {
            fluid(maxWidth: 425, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  // 210x80 / 197x75
  return (
    <Img
      alt={`Logo ${title}`}
      title={title}
      fluid={logoImage.childImageSharp.fluid}
    />
  )
}

export default Logo
