import styled from "styled-components"
import media from "styled-media-query"
import { Link as oLink } from "gatsby"

export const fh_Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`

export const fh_Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const fh_Link = styled(oLink)`
  display: flex;
  color: #506a85;
  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  &:hover {
    color: #1fa1f2;
    svg {
      transition: fill 0.5s;
      fill: #1fa1f2 !important;
    }
  }
  ${media.between("768px", "999px")`
    /* margin-left: 25px; */
  `}
`

export const fh_TextWrapper = styled.div`
  /* fill: #506a85; */
  /* height: 19px; */
`

export const fh_Text = styled.span`
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: bold;
  color: #707e8c;

  ${media.between("768px", "1150px")`
    font-size: 1.2vw;
  `}
`
export const fh_Divisor = styled.span`
  color: #707e8c;
  margin: 0 10px;
  cursor: default;
`

export const fh_IconWrapper = styled.div`
  fill: #506a85;
  width: 19px;
`
