import React, { useState } from "react"
import PropTypes from "prop-types"

import GlobalStyles from "../../styles/global"
import Header from "../Header"
import Footer from "../Footer"

import * as S from "./styled"

const Layout = ({ children }) => {
  return (
    <S.Container>
      <GlobalStyles />
      {children}
    </S.Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
