import styled from "styled-components"
import media from "styled-media-query"
import { Link as oLink } from "gatsby"

export const fi_Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 100vw; */
  height: 365px;
  background: #2f4052;

  ${media.lessThan("medium")`
    /* height: 72px; */

    /* background: #f5f7fa; */
    /* border-bottom: 2px solid #e6e9ed; */
  `}
`

export const fi_Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  /* padding: 0 15px; */

  ${media.lessThan("1334px")`
    max-width: 90vw;
  `}/* kesDev */
  /* background: #123456; */
`

export const fi_Grid = styled.div`
  height: 315px;
  /* width: 100%; */
  /* height: 100%; */
  padding: 15px;
  margin-top: 50px;
  width: 25%;

  /* background: red;
  border: 1px solid black; */
`

export const fi_LogoDiv = styled.div`
  max-width: 75%;
  /* width: 100%; */
  /* width: 17%; */
`

export const fi_AboutDiv = styled.div`
  margin: 30px 0;

  ${media.between("768px", "814px")`
    margin: 15px 0;
  `}
  ${media.between("815px", "820px")`
    margin: 20px 0;
  `}
`

export const fi_AboutText = styled.span`
  color: #a3b1bf;
  font-size: 14px;
`

export const fi_MoreDiv = styled.div``

export const fi_MoreLink = styled(oLink)`
  display: flex;
  color: #ffffff;
  text-decoration: none;
  float: left;

  /* transition: font-size 0.1s; */
  transition: margin-left 0.5s;

  /* align-items: center; */
  font-size: 15px;
  font-weight: bold;
  &:hover {
    color: #ffffff;
    /* font-size: 15px; */
    margin-left: 5px;
    svg {
      transition: fill 0.5s;
      fill: #ffffff !important;
    }
  }
`

export const fi_TitleDiv = styled.div`
  margin-bottom: 27px;
`

export const fi_TitleText = styled.h1`
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
`

export const fi_TitleSubDiv = styled.div`
  margin-bottom: 27px;
`

export const fi_TitleSubText = styled.h2`
  color: #a3b1bf;
  font-size: 14px;
  ${media.between("768px", "824px")`
    font-size: 13px;
  `}
`

export const fi_VideoDiv1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.between("768px", "1160px")`
    flex-direction: column;
  `}
`

export const fi_VideoDiv2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.between("768px", "1160px")`
    display: none;
  `}
`

export const fi_VideoContainer = styled.div`
  flex: 1;
  margin: ${props => (props.position === "L" ? "0 3px 6px 0" : "0 0 6px 3px")};
  background: blue;
  min-height: 85px;
  ${media.between("768px", "1160px")`
    min-height: 110px;
    margin: 0 0 6px 0;
  `}
`

export const fi_ContactDiv = styled.div`
  /* margin-bottom: 27px; */
`

export const fi_ContactText = styled.span`
  display: flex;
  align-items: center;
  color: #a3b1bf;
  font-size: 14px;
  margin-bottom: ${props => (props.icon ? "10px" : "15px")};
  padding-left: ${props => (!props.icon ? "20px" : 0)};
  ${media.between("768px", "824px")`
    font-size: 10px;
  `}
  ${media.between("825px", "879px")`
    font-size: 11px;
  `}
  ${media.between("880px", "929px")`
    font-size: 12px;
  `}
  ${media.between("930px", "985px")`
    font-size: 13px;
  `}
`

export const fi_IconWrapper = styled.div`
  fill: #a3b1bf;
  width: 15px;
  margin-right: 5px;
`

export const fi_CourseDiv = styled.div`
  /* margin-bottom: 27px; */
`

export const fi_CourseLink = styled(oLink)`
  display: flex;
  align-items: center;
  color: #a3b1bf;
  font-size: 14px;
  margin-bottom: ${props => (props.icon === "true" ? "10px" : "15px")};
  padding-left: ${props => (!props.icon === "true" ? "20px" : 0)};

  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  &:hover {
    color: #1fa1f2;
    svg {
      transition: fill 0.5s;
      fill: #1fa1f2 !important;
    }
  }

  ${media.between("768px", "859px")`
    font-size: 11px;
  `}
  ${media.between("860px", "929px")`
    font-size: 11px;
  `}
  ${media.between("930px", "969px")`
    font-size: 12px;
  `}
  ${media.between("970px", "1030px")`
    font-size: 13px;
  `}
`

export const fi_CourseMoreDiv = styled.div`
  margin-top: 30px;
`

export const fi_CourseMoreLink = styled(oLink)`
  display: flex;
  color: #ffffff;
  text-decoration: none;
  float: left;

  /* transition: font-size 0.1s; */
  transition: margin-left 0.5s;

  /* align-items: center; */
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  &:hover {
    color: #ffffff;
    /* font-size: 15px; */
    margin-left: 5px;
    svg {
      transition: fill 0.5s;
      fill: #ffffff !important;
    }
  }
`
