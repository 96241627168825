import styled from "styled-components"
import media from "styled-media-query"
import { Link as oLink } from "gatsby"

export const nh_Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${media.lessThan("large")`
    /* display: none; */
  `}

  .active {
    span {
      color: #1fa1f2;
    }
  }
`

export const nh_Link = styled(oLink)`
  display: flex;
  margin-left: 30px;
  color: #506a85;
  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  &:hover {
    color: #1fa1f2;
    svg {
      transition: fill 0.5s;
      fill: #1fa1f2 !important;
    }
  }
  ${media.between("768px", "999px")`
    margin-left: 25px;
  `}
`

export const nh_TextWrapper = styled.div`
  /* fill: #506a85; */
  /* height: 19px; */
`

export const nh_Text = styled.span`
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;

  ${media.between("768px", "999px")`
    font-size: 1.2vw;
  `}
  ${media.between("900px", "1150px")`
    font-size: 1.2vw;
  `}
  
`

export const nh_IconWrapper = styled.div`
  fill: #506a85;
  width: 19px;
`
