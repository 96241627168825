import styled from "styled-components"
import media from "styled-media-query"

export const fn_Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 100vw; */
  height: 84px;
  background: #283645;
  border-bottom: 2px solid #e6e9ed;

  ${media.lessThan("medium")`
    /* height: 72px; */

    /* background: #f5f7fa; */
    /* border-bottom: 2px solid #e6e9ed; */
  `}
`

export const fn_Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  padding: 0 15px;

  ${media.lessThan("1334px")`
    max-width: 90vw;
  `}/* kesDev */
  /* background: #123456; */
`

export const fn_DivTitle = styled.div`
  /* height: 19px; */
  /* width: 100%; */
  width: 20%;
`

export const fn_NavItens = styled.div`
  width: 80%;
  ${media.lessThan("medium")`
    /* display: none; */
  `}
`
export const fn_Title = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #707e8c;
  cursor: default;

  /* height: 19px; */
  /* width: 100%; */
  /* width: 17%; */
`
