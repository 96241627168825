import styled from "styled-components"
import media from "styled-media-query"

export const hs_Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 42px;
  background: #f5f7fa;
  border-bottom: 2px solid #e6e9ed;
`

export const hs_Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  padding: 0 15px;

  ${media.lessThan("1334px")`
    max-width: 90vw;
  `}
  
  /* kesDev */
  /* background: #123456; */
`

export const hs_DivMail = styled.div`
  height: 19px;
`

export const hs_DivSocial = styled.div`
  
`
